import classnames from "classnames";
import React from "react";
import {
  AlertWarningInfoCircle,
  AlertWarningTriangle,
  CloseCircle,
  CloseCrossX,
  ValidationCheckCircle,
} from "../Icons";

export type NotificationProps = {
  type: "info" | "error" | "warning" | "success";
  title?: React.ReactNode;
  description?: React.ReactNode;
  actions?: Array<{
    text: React.ReactNode;
    clickHandler: React.MouseEventHandler<HTMLButtonElement> | undefined;
  }>;
  dismiss?: () => void;
  className?: string;
  onDark?: boolean;
  icon?: React.ReactNode;
};

type NotificationComponentType = React.FunctionComponent<NotificationProps>;

const Notification: NotificationComponentType = ({
  title,
  className,
  actions,
  description,
  dismiss,
  type,
  icon,
}) => (
  <div
    className={classnames(
      "max-w-[400px] rounded  border-[2px] p-4",
      className,
      {
        ["border-alert bg-yellow-200"]: type === "warning",
        ["border-positive bg-green-200"]: type === "success",
        ["border-tertiary bg-primary-alt"]: type === "info",
        ["border-negative bg-red-200"]: type === "error",
      },
    )}
  >
    <div className="flex items-center">
      <div className="h-6 w-6 flex-shrink-0 self-center">
        {icon && icon}
        {!icon && (
          <>
            {type === "error" && <CloseCircle />}
            {type === "success" && <ValidationCheckCircle />}
            {type === "warning" && <AlertWarningTriangle stroke="black" />}
            {type === "info" && <AlertWarningInfoCircle />}
          </>
        )}
      </div>
      {/* <div className="flex-shrink-0">
         <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
      </div> */}
      <div className="ml-3">
        <h3 className={classnames("text-sm font-bold text-invert-primary")}>
          {title}
        </h3>
        {description && (
          <div className={classnames("mt-2 text-sm text-invert-primary")}>
            {description}
          </div>
        )}
      </div>
      {dismiss && (
        <div className="ml-auto self-center pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => dismiss()}
              className={classnames(
                "inline-flex scale-75 rounded-md p-1.5 text-invert-primary  focus-visible:outline-none  focus-visible:ring-2 focus-visible:ring-invert-primary focus-visible:ring-offset-2 focus-visible:ring-offset-transparent",
              )}
            >
              <span className="sr-only">Dismiss</span>
              <CloseCrossX width={24} height={24} aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
    {actions && (
      <div className="ml-9 mt-4">
        <div className="-mx-2 -my-1.5 flex gap-x-2.5">
          {actions.map((action) => (
            <button
              type="button"
              key={action.text?.toString()}
              onClick={action.clickHandler}
              className={classnames(
                "rounded-md px-2 py-1.5 text-sm font-medium text-invert-primary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-invert-primary focus-visible:ring-offset-2 focus-visible:ring-offset-transparent",
              )}
            >
              {action.text}
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
);

export { Notification };
