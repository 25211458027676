"use client";

import { Dialog, Transition } from "@headlessui/react";
import { Button, CloseCrossX } from "@submix/component-library";
import classNames from "classnames";
import React, { Fragment } from "react";
import { PanelTitle, PanelTitleProps } from "./PanelTitle";
import { PanelFooter, PanelFooterProps } from "./PanelFooter";

export type PanelProps = {
  isOpen: boolean;
  onClose: () => void;
  openFromRight?: boolean;
  showCloseButton?: boolean;
  className?: string;
  children?: React.ReactNode;
  dialogPanelClassName?: classNames.Argument;
};

export type PanelStatic = {
  Footer: React.FunctionComponent<PanelFooterProps>;
  Title: React.FunctionComponent<PanelTitleProps>;
};

const Panel: React.FunctionComponent<PanelProps> & PanelStatic = ({
  isOpen,
  onClose,
  openFromRight = true,
  showCloseButton = true,
  className,
  children,
  dialogPanelClassName,
}: PanelProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={classNames("relative z-30 ", className)}
        onClose={onClose}
      >
        <div
          className={classNames(
            "pointer-events-none fixed inset-y-0 flex max-w-full ",
            {
              "pr-8": !openFromRight,
              "right-0 pl-10": openFromRight,
            },
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom={classNames({
              "translate-x-full": openFromRight,
              "-translate-x-full": !openFromRight,
            })}
            enterTo={classNames({
              "translate-x-0": openFromRight,
              "-translate-x-0": !openFromRight,
            })}
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo={classNames({
              "translate-x-full": openFromRight,
              "-translate-x-full": !openFromRight,
            })}
          >
            <Dialog.Panel
              className={classNames(
                "pointer-events-auto relative bg-neutral-50 shadow-2xl shadow-black/50 ",
                dialogPanelClassName,
              )}
            >
              {showCloseButton && (
                <>
                  <button
                    onClick={onClose}
                    className={classNames(
                      "absolute top-large  z-30 hidden rounded bg-invert-secondary p-[10px] min-[500px]:block",
                      {
                        "-left-[18px]": openFromRight,
                        "-right-[18px]": !openFromRight,
                      },
                    )}
                  >
                    <CloseCrossX
                      stroke="#fff"
                      height={16}
                      width={16}
                      strokeWidth={2}
                    />
                  </button>
                  <Button.CloseButton
                    color="black"
                    className="absolute right-3 top-3 z-30 block min-[500px]:hidden [&_span]:!border-transparent"
                    onClick={onClose}
                  />
                </>
              )}
              <div className="relative h-full flex-1 p-3 pt-14 min-[500px]:p-7">
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

Panel.Title = PanelTitle;
Panel.Footer = PanelFooter;

export { Panel };
