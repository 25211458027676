"use client";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Notification } from "../components/Notification";

export interface NotificationProps {
  type: "error" | "info" | "success" | "warning";
  text: {
    title: string;
    description: string;
  };
  icon?: React.ReactNode;
  dismiss?: boolean;
  callback?: () => void;
}

interface NotificationContext {
  addNotification: (notification: NotificationProps) => void;
}

const NotificationContext = React.createContext<NotificationContext | null>(
  null,
);

export const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === null) {
    throw new Error(
      "useNotification must be used within a NotificationProvider",
    );
  }
  return context;
};

interface StoreProps {
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
}

export const NotificationProvider = ({
  children,
  containerStyle,
}: StoreProps) => {
  const addNotification = useCallback((notification: NotificationProps) => {
    if (notification.callback && typeof notification.callback === "function") {
      notification.callback();
    }
    if (notification.dismiss) {
      toast.custom((t) => (
        <Notification
          type={notification.type}
          title={notification.text.title}
          description={notification.text.description}
          dismiss={() => toast.dismiss(t.id)}
          icon={notification.icon}
        />
      ));
    } else {
      toast.custom(
        <Notification
          type={notification.type}
          title={notification.text.title}
          description={notification.text.description}
          icon={notification.icon}
        />,
      );
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
      }}
    >
      {children}
      <Toaster
        containerStyle={containerStyle}
        position="top-right"
        reverseOrder={false}
      />
    </NotificationContext.Provider>
  );
};
