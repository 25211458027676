"use client";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import classnames from "classnames";
import { ComponentProps, useCallback, useMemo } from "react";
import { WithContext as ReactTags, Tag } from "react-tag-input";
import { Typography } from "../Typography";

const KeyCodes = {
  space: 32,
  tab: 9,
  comma: 188,
  enter: 13,
};

const defaultDelimiters = [
  KeyCodes.space,
  KeyCodes.tab,
  KeyCodes.comma,
  KeyCodes.enter,
];

export type Chip = {
  id: string;
  [key: string]: string | null | JSX.Element;
};

export type Suggestion = {
  id: string;
  [key: string]: string;
};

interface ChipInputProps {
  delimiters?: number[];
  placeholder?: string;
  renderTag?: any;
  className?: string;
  label?: string;
  onDark?: boolean;
  error?: string;
  tags: Chip[];
  suggestions?: Chip[];
  setTags: (tags: any, update?: boolean) => void;
  labelField?: string;
  warning?: string;
  renderSuggestion?: (
    tag: Chip,
    query: string,
  ) => string | React.ReactNode | void;
  handleFilterSuggestions?: (
    inputValue: string,
    possibleSuggestions: Chip[],
  ) => Chip[];
  tagsClassNames?: {
    tag?: string;
    remove?: string;
    tagInputField?: string;
    selected?: string;
    tagInput?: string;
    suggestions?: string;
    activeSuggestion?: string;
  };
}

const ChipInput = ({
  delimiters = defaultDelimiters,
  placeholder = "Enter email",
  renderTag,
  className,
  label = "",
  onDark,
  error,
  tags,
  setTags,
  labelField,
  warning,
  suggestions = [],
  renderSuggestion,
  handleFilterSuggestions,
  tagsClassNames,
}: ChipInputProps) => {
  const handleDelete = useCallback(
    (i: number) => {
      setTags(tags.filter((tag, index) => index !== i));
    },
    [setTags, tags],
  );

  const handleAddition = useCallback(
    (tag: Chip) => {
      if (renderTag) {
        tag = renderTag(tag);
      }
      if (!tag) return;

      setTags([...tags, tag], /* update */ true);
    },
    [renderTag, setTags, tags],
  );

  const handleDrag = useCallback(
    (tag: Chip, currPos: number, newPos: number) => {
      const newTags = tags.slice();

      newTags.splice(currPos, 1);
      newTags.splice(newPos, 0, tag);

      // re-render
      setTags(newTags);
    },
    [setTags, tags],
  );

  const handleInputBlur = useCallback(
    (input: string) => {
      if (input !== "") {
        let tag: Chip = { id: input, user: input };
        if (renderTag) {
          tag = renderTag(tag);
        }
        if (!tag) return;
        setTags([...tags, tag]);
      }
    },
    [setTags, renderTag, tags],
  );

  const classNames = useMemo(
    () => ({
      tag: classnames(
        "@apply py-2 px-4 bg-neutral-200 min-h-11 rounded-full flex self-center font-normal items-center",
        tagsClassNames?.tag,
      ),
      remove: classnames(
        "@apply transparent border-0 ml-3 text-2xl leading-none	",
        tagsClassNames?.remove,
      ),
      tagInputField: classnames(
        "@apply p-1.5 border-transparent w-full focus-visible:border-none focus-visible:outline-none font-normal min-w-40",
        tagsClassNames?.tagInputField,
      ),
      selected: classnames(
        "@apply border py-2.5 px-4 flex relative rounded mt-1 flex-row border-neutral-500 flex-wrap items-center gap-2.5 focus-within:ring-invert-primary focus-within:ring-2 focus-within:!ring-offset-4",
        tagsClassNames?.selected,
      ),
      tagInput: classnames(
        "@apply flex flex-1 border-neutral-500 font-normal",
        tagsClassNames?.tagInput,
      ),
      suggestions: classnames(
        "@apply absolute top-full z-50 bg-secondary shadow-lg rounded border-neutral-200 border-2 w-full left-0  py-2.5 px-3.5 ",
        tagsClassNames?.suggestions,
      ),
      activeSuggestion: classnames(
        "bg-secondary-alt cursor-pointer",
        tagsClassNames?.activeSuggestion,
      ),
    }),
    [
      tagsClassNames?.tag,
      tagsClassNames?.remove,
      tagsClassNames?.tagInputField,
      tagsClassNames?.selected,
      tagsClassNames?.tagInput,
      tagsClassNames?.suggestions,
      tagsClassNames?.activeSuggestion,
    ],
  );

  return (
    <Typography.Body element="div" onDark={onDark} className={className}>
      {label}
      <ReactTags
        /* Uncomment shouldRenderSuggestions prop if you need suggestion dropdown to be always open while
      debugging or styling it */
        //shouldRenderSuggestions={(query) => Boolean(query)}
        tags={tags as unknown as Tag[]}
        suggestions={suggestions as unknown as Tag[]}
        renderSuggestion={
          renderSuggestion as unknown as ComponentProps<
            typeof ReactTags
          >["renderSuggestion"]
        }
        handleFilterSuggestions={
          handleFilterSuggestions as unknown as ComponentProps<
            typeof ReactTags
          >["handleFilterSuggestions"]
        }
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleInputBlur={handleInputBlur}
        handleDrag={handleDrag}
        labelField={labelField}
        autofocus={false}
        inputFieldPosition="inline"
        placeholder={tags && tags.length === 0 ? placeholder : ""}
        classNames={classNames}
      />
      <Typography.ErrorMessage
        className={classnames(
          `invisible block min-h-6 pt-2`,
          (error || warning) && "!visible font-normal",
          warning && "!text-yellow-700",
        )}
      >
        {error && error}
        {warning && warning}
      </Typography.ErrorMessage>
    </Typography.Body>
  );
};

export { ChipInput };
